<template>
  <page :styleWrap="{'background': '#fff'}">
    <div style="padding: 15px">
      <header-bar>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>
      <div>
        <van-field class="f_flex f_a_c fieldCell" placeholder="输入旧密码" v-model="pwd" label-width="100" :type="inputType">
          <!-- <div slot='label' style="font-size: 15px;  color: #BABABA"></div> -->
          <template #button>
            <toggle-pwd :inputType="inputType" @change='changeInputType'></toggle-pwd>
          </template>
        </van-field>

        <van-field class="f_flex f_a_c fieldCell" placeholder="输入新密码" v-model="pwd1" label-width="100" :type="inputType1">
          <!-- <div slot='label' style="font-size: 15px;  color: #BABABA">输入新密码</div> -->
          <template #button>
            <toggle-pwd :inputType="inputType1" @change='changeInputType1'></toggle-pwd>
          </template>
        </van-field>

        <van-field class="f_flex f_a_c fieldCell" placeholder="再次输入密码" v-model="pwd2" label-width="100" :type="inputType2">
          <!-- <div slot='label' style="font-size: 15px;  color: #BABABA">再次输入密码</div> -->
          <template #button>
            <toggle-pwd :inputType="inputType2" @change='changeInputType2'></toggle-pwd>
          </template>
        </van-field>

        <div style="margin-top: 8px" class="f_flex f_a_c f_j_sb">
          <p class="tip">请设置6-18位密码</p>
          <!-- <p class="forgetTip theme_c">忘记密码</p> -->
        </div>
      </div>

      <button @click='submitFn' class="btn_radius margin_auto register_btn " :class="[getPwd ? 'theme_bg' : 'theme_dis_bg']">确认修改</button>
    </div>
  </page>
</template>

<script>
import { Field } from 'vant';
import togglePwd from './togglePwd.vue'
import loginApi from '@/api/user'

export default {
  data: ()=> ({
    pwd: '',
    pwd1: '',
    pwd2: '',
    inputType: 'password',
    inputType1: 'password',
    inputType2: 'password'
  }),
  components: {
    'van-field': Field,
    'toggle-pwd': togglePwd
  },
  computed: {
    getPwd: function() {
      return this.pwd && this.pwd1 === this.pwd2 && this.pwd1 !== ''
    }
  },
  methods: {
    changeInputType(e) {
      this.inputType = e
    },
    changeInputType1(e) {
      this.inputType1 = e
    },
    changeInputType2(e) {
      this.inputType2 = e
    },
    submitFn() {
      if (!this.pwd1.match(/^\S{6,18}$/)) {
        this.$toast('密码设置6-18位')
        return false;
      }
      if (this.getPwd) {
        loginApi.changePassword({
          oldPassword: this.pwd,
          newPassword: this.pwd1,
          confirmPassword: this.pwd2
        }).then(res => {
          if(res.data) {
            this.$notify({ type: 'primary', message: res.data.msg })
            this.$router.back()
          }
        })
      }
    }
  },
}
</script>

<style lang="less" scoped>
.phoneTip {
  font-size: 13px;
  color: #666;
  margin-top: 60px;
  margin-bottom: 45px;
}
.fieldCell {
  height: 58px;
  font-size: 15px;
  border-bottom: 1px solid #E4E4E4;
  padding: 0;
}
.fieldLabel {
  color: #BABABA;
  font-size: 15px;
}
.register_btn {
  width: 70%;
  margin-top: 94px;
}
.tip {
  font-size: 12px; 
  color: #9B9B9B; 
}
.forgetTip {
  font-size: 12px; 
  text-align: right;
  &:active {
    color: darkgoldenrod;
  }
}
</style>